import { isMobile } from '../../../libs/userAgent';
import LineSVGContainer from '../line';
import './index.scss'

const offerList = [
  {
    title: '1-on-1 Guidance',
    content: 'Get 1-on-1 support from a dedicated Creator Manager to perfect your strategy and content right from day 1.'
  },
  {
    title: 'Exclusive Campaigns & Battles',
    content: "Participate in BlinkBloc’s campaigns and cross-agency battles to boost your account and engage your audience."
  },
  {
    title: 'Traffic Support',
    content: "We help you get more views and engagement through smart promotions and collaborations."
  },
  {
    title: 'Brand Partnerships',
    content: "Connect with brands for sponsorships and collaborations that enhance your content and boost your earnings."
  },
  {
    title: "Milestone Rewards",
    content: "Get rewards as you achieve significant milestones in followers, engagement, and content creation."
  },
  {
    title: 'Community & Networking',
    content: "Join a supportive community of creators, offering networking opportunities and collaborative growth."
  }
]

// const teamArr = [
//   {
//     name: 'Liz',
//     avatar: require('../../../assets/img/KevinMa.png').default
//   },
//   {
//     name: 'Kevin Ma',
//     avatar: require('../../../assets/img/KevinMa2.png').default
//   },
//   {
//     name: 'Ashley',
//     avatar: require('../../../assets/img/Ashley.png').default
//   },
//   {
//     name: 'Joshua',
//     avatar: require('../../../assets/img/Joshua.png').default
//   },
//   {
//     name: 'Johnny',
//     avatar: require('../../../assets/img/Johnny.png').default
//   }
// ]

const AboutComp = (): JSX.Element => {
  return (
    <>
      <div className={isMobile() ? "mAbout about" : "about"}>
        <div className="banner">
          <div className="title">
            <div className="svg">
              <LineSVGContainer />
            </div>
            <h1>About us</h1>
          </div>
        </div>
        <div className="explain">
          <h2>Your TikTok journey starts here <br />
            Grow, engage, and monetize with BlinkBloc</h2>
          <p>
            As live streaming becomes a driving force in the digital world, Blinkbloc stands by your side, offering the guidance, support, and custom strategies you need to confidently jumpstart your content creator journey. We believe every creator is a unique bloc that shines in its own way. When one bloc connects with another, and all blocs unite, they form a new world.
            <br />
            <br />
            <span>
              Together, we shine like never before.
            </span>

          </p>
        </div>
        <div className="offer">
          <h2>What We Can Offer?</h2>
          <div className="list">
            {
              offerList.map((ele, index) =>
                <div className="item" key={index}>

                  <h3>{ele.title}</h3>
                  <p>{ele.content}</p>
                </div>

              )
            }

          </div>
        </div>
        <div className="picWall">
          <div className="item">
            <img src={require('../../../assets/img/wall1.png').default} alt="" />
          </div>
          <div className="item">
            <img src={require('../../../assets/img/wall2.png').default} alt="" />
          </div>
          <div className="item">
            <img src={require('../../../assets/img/wall3.png').default} alt="" />
          </div>
        </div>
        <div className="story">
          <h2>OUR STORY</h2>
          <p>
            Blinkbloc is a vibrant team of creator-obsessed individuals—a TikTok live streaming, influencer marketing, and talent agency. Drawing on our experience with over 1,000 talented creators across the world’s most exciting platforms, including TikTok, YouTube, Instagram, and Twitch, we empower clients and creators to build scalable businesses and create authentic partnerships.
          </p>
          <div className="des">
            We envision a future where every brand has its own in-house creators, and our mission is to make that future a reality today.
          </div>
        </div>
        {/* <div className="meet">
          <div className="content">
            <h2>Meet our team</h2>
            <div className="card-container list">
              <div className="card-row">
                {
                  teamArr.slice(0, 3).map((ele, index) => (
                    <div key={`meet${index}`} className="card-item">
                      <div className="avatar">
                        <img src={ele.avatar} alt="" />
                      </div>
                      <h3>{ele.name}</h3>
                    </div>
                  ))
                }
              </div>
              <div className="card-row center-row">
                {
                  teamArr.slice(3, 5).map((ele, index) => (
                    <div key={`meet${index + 3}`} className="card-item">
                      <div className="avatar">
                        <img src={ele.avatar} alt="" />
                      </div>
                      <h3>{ele.name}</h3>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <a href="/apply" className='btn'>
            <img src={require('../../../assets/img/join_btn.png').default} alt="" />

            Join Now
          </a>
        </div> */}
      </div>

    </>
  );
}

export default AboutComp;